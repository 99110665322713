<template>
	<div class="page">
		<van-nav-bar title="收款账户" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<div v-if="co_mode==1">
			<van-swipe-cell v-for="(item,key) in cardList" :key="key">
				<div class="mt bg-white padding">
					<div class="flex text-df" style="justify-content: space-between;">
						<div class="flex" style="align-items: center;">
							<div class="card-left">银行</div>
							<div>{{item.account_bank}}</div>
						</div>
						<div class="flex" style="align-items: center;"
							@click="onDefault(item.account_bank,item.account_num,item.id)">
							<div>
								<van-icon name="checked" size="20" color="#F15223" v-if="item.is_default==1" />
								<van-icon name="circle" size="20" color="#9F9B9B" v-else />
							</div>
							<div class="text-gray">默认</div>
						</div>
					</div>
					<div class="flex text-df mt" style="align-items: center;">
						<div class="card-left">卡号</div>
						<div>{{item.account_num}}</div>
					</div>
				</div>

				<template #right>
					<div class="card-right modify fl" @click="onCard(item.id)">修改</div>
					<div class="card-right del fl" @click="delCard(item.id,item)">删除</div>
				</template>
			</van-swipe-cell>

		</div>
		<!-- 企业 个体户 -->
		<div v-if="co_mode!=1">
			<div class="padding bg-white mt text-df" v-for="(item,key) in cardList" :key="key">
				<div class="flex" style="align-items: center;">
					<div class="card-left">银行账户</div>
					<div>{{item.account_num}}</div>
				</div>
				<div class="flex" style="align-items: center;margin-top: 0.6rem;">
					<div class="card-left">开户银行</div>
					<div style="width: 4.9rem;">{{item.account_bank}}</div>
				</div>
			</div>
		</div>

		<div class="card-btn" @click="onCard()" v-if="co_mode==1">
			<div>
				<van-icon name="add" size="20" />
			</div>
			<div class="ml">添加银行卡</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				co_mode: '', //1 个人 2 企业 3 个体工商户
				openid: 2,
				login_token: localStorage.getItem("login_token"),
				cardList: [],
			}
		},
		mounted() {
			var _this = this;
			_this.co_mode = _this.$route.query.co_mode;
			_this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});
			var myData = new URLSearchParams();
			myData.append("openid", _this.openid);
			myData.append("login_token", _this.login_token);
			_this.$http({
				method: 'post',
				url: 'users/api.finance/getUserBankInfo',
				data: myData
			}).then((res) => {
				_this.$toast.clear();
				if (res.data.code == 0) {
					console.log(res)
					_this.cardList = res.data.data
				} else if (res.data.code == -100) {
					_this.$dialog.alert({
						title: '标题',
						message: '登录过期，请重新登录',
					}).then(() => {
						_this.$router.push({
							path: "/login"
						})
					});

				} else {
					_this.$toast(res.data.msg);
				}
			}).catch(() => {
				_this.$toast.clear();
				_this.$toast('网络请求失败');
			});

		},
		methods: {
			//返回
			onClickLeft() {
				console.log(window.history.length)
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			delCard(id,item){
				var _this = this;
				_this.$dialog.confirm({
					message: '确定删除吗？',
				}).then(() => {
					var myData = new URLSearchParams();
						myData.append("openid", _this.openid);
						myData.append("login_token", _this.login_token);
						myData.append("id", id);
					_this.$http({
						method: 'post',
						url: 'users/api.finance/delUserBankInfo',
						data: myData,
					}).then((res) => {
						if (res.data.code == 0) {
							console.log(res);
							var index =_this.cardList.indexOf(item);
							res.data.edit_default?this.$router.go(0):_this.cardList.splice(index,1);
						} else if (res.data.code == -100) {
							_this.$dialog.alert({
								title: '标题',
								message: '登录过期，请重新登录',
							}).then(() => {
								_this.$router.push({
									path: "/login"
								})
							});

						} else {
							_this.$toast(res.data.msg);
						}
					}).catch(() => {
						_this.$toast('网络请求失败');
					});

				}).catch(()=>{
					console.log("取消")
				});
				
			},
			//修改---添加
			onCard(id) {
				var _this = this;
				if (id) {
					console.log(id)
					//修改
					_this.$router.push({
						path: "/realname/card/add",
						query:{
							id:id
						}
					})
				} else {
					//添加
					_this.$router.push({
						path: "/realname/card/add"
					})
				}


			},
			//选择默认银行卡
			onDefault(account_bank, account_num,id) {
				console.log(account_bank, account_num)
				var _this=this;
				_this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0
				});
				var myData = new URLSearchParams();
				myData.append("openid", _this.openid);
				myData.append("login_token",_this.login_token);
				myData.append("account_bank",account_bank);
				myData.append("account_num",account_num);
				myData.append("is_default","1");
				myData.append("id",id);
				_this.$http({
					method: 'post',
					url: 'users/api.finance/addUserBankInfo',
					data: myData
				}).then((res) => {
					_this.$toast.clear();
					if (res.data.code == 0) {
						console.log(res)
						for(var i=0;i<_this.cardList.length;i++){
							if(_this.cardList[i].id==id){
								_this.cardList[i].is_default=1
							}else{
								_this.cardList[i].is_default=0
							}
						}
					} else if (res.data.code == -100) {
						_this.$dialog.alert({
							title: '提示',
							message: '登录过期，请重新登录',
						}).then(() => {
							_this.$router.push({
								path: "/login"
							})
						});

					} else {
						_this.$toast(res.data.msg);
					}
				}).catch(() => {
					_this.$toast.clear();
					_this.$toast('网络请求失败');
				});
			},




		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>

<style scoped="scoped">
	.ml {
		margin-left: 0.1rem;
	}

	.card-btn {
		width: 6.42rem;
		height: 0.96rem;
		background-color: #F15223;
		color: #FFFFFF;
		font-size: 0.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-top: 1rem;
		margin-bottom: 0.3rem;
		border-radius: 0.08rem;
	}

	.card-right {
		width: 1.3rem;
		height: 100%;
		color: #FFFFFF;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.3rem;
	}
	.modify{
		background-color: #FFAB48;
	}
	.del{
		background-color: #FF4848;
	}
	.card-left {
		width: 2rem;
		text-align: center;
	}

	.text-act {
		color: #F15223;
	}

	.text-gray {
		color: #9F9B9B;
		margin-left: 0.1rem;
	}

	.text-df {
		font-size: 0.3rem;
	}

	.flex {
		display: flex;
	}

	.mt {
		margin-top: 0.3rem;
	}

	.padding {
		padding: 0.3rem;
	}

	.bg-white {
		background-color: #FFFFFF;
	}

	.page {
		background-color: #F6F6F6;
		height: 100%;
	}
</style>
